import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './router'
import App from './App.vue'

// debug config
Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter);

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
