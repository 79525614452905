<template>
    <div class="container">
        <h1>JB - Labs</h1>

        <ul>
            <li><a href="#">Exp01: Glassmorphism</a></li>
        </ul>
    </div>
</template>

<script>

    export default {
        name: 'Labs',
        mounted() {
          
        },
        methods: {
          
        }
    }
</script>

<style scoped>


</style>
