<template>
    <div id="app">
        <header>
            <div id="particles-header"></div>
        </header>
        <div class="container">
            <main role="main" class="pb-3">
                <router-view />
            </main>
        </div>
        <footer class="border-top footer text-muted">
            <div class="container">
                <div class="row">
                    <div class="col">
                        &copy; 2013-{{currentyear}} jorisbeekhuizen.nl
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'App',
        data: function(){ 
            return {
                currentyear: new Date().getFullYear()
            }
        },
        components: {}
    }
</script>

<style>
        @import url(https://fonts.googleapis.com/css?family=Raleway:200,300,400);
    
        html,
        body {
            font-family: 'Raleway', sans-serif;
            font-size: 14px;
        }

        html {
            position: relative;
            min-height: 100%;
        }

        body {
            margin-bottom: 60px;
            background-color: #efefef;
        }

        h1, h2, h3, h4, h5 {
            font-family: 'Raleway', sans-serif;
            font-weight: 100;
        }

        @media (min-width: 768px) {
            html {
                font-size: 16px;
            }
        }
       
        header {
            background-image: linear-gradient(135deg, #003073, #029797);  /* #1125aa*/
           /* background-color: #1125aa;*/
            width: 100%;
            height: 450px;
        }

            header > div {
                width: 100%;
                height: 100%;
            }

        @media (max-width: 768px) {
            header {
               height: 350px;
            }
        }

        /* Provide sufficient contrast against white background */
        a {
            color: #0366d6;
        }

        .btn-primary {
            color: #fff;
            background-color: #1b6ec2;
            border-color: #1861ac;
        }

        .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
            color: #fff;
            background-color: #1b6ec2;
            border-color: #1861ac;
        }

        .jb-shadow {
            box-shadow: 0 1px 6px rgb(0 0 0 / 12%), 0 1px 4px rgb(0 0 0 / 24%);
        }

        /* Sticky footer styles
    -------------------------------------------------- */
        .border-top {
            border-top: 1px solid #e5e5e5;
        }

        .border-bottom {
            border-bottom: 1px solid #e5e5e5;
        }

        .footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            white-space: nowrap;
            line-height: 60px;
        }
</style>

























<!--<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>-->
