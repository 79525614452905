<template>
    <div class="container">

        <div id="about-me" class="container d-flex flex-column bg-white mx-auto jb-shadow">
            <div id="about-me-content" class="row flex-grow-1">
                <div class="col-md-5 col-sm-12 py-4 px-4">
                    <h1 class="name">Joris <br /> Beekhuizen</h1>
                </div>
                <div class="col-md-7 col-sm-12 py-4 px-4 text-lg-center">
                    <h2>About Me</h2>
                    <p>
                        .NET Software Developer | web & azure development | fotografie <a href="https://photos.jorisbeekhuizen.nl" target="_blank" title="foto's'" rel="nofollow"><i class="bi bi-box-arrow-up-right"></i></a> | home automation |  karateka | lezen | leren.
                    </p>
                </div>
            </div>
            <div class="row">
                <div id="about-me-footer" class="col-12 d-flex justify-content-center">
                    <a href="https://nl.linkedin.com/in/jorisbeekhuizen" target="_blank" class="icon-link d-flex" title="Linkedin"><i class="bi bi-linkedin align-self-center"></i></a>
                    <!--<a href="https://nl.linkedin.com/in/jorisbeekhuizen" class="icon-link d-flex" target="_blank" title="LinkedIn"><i class="bi bi-envelope-fill align-self-center"></i></a>-->
                    <a href="https://photos.jorisbeekhuizen.nl" class="icon-link d-flex" target="_blank" title="foto's" rel="nofollow"><i class="bi bi-camera2 align-self-center"></i></a>
                </div>
            </div>
        </div>

        <div class="row my-skills">
            <div class="col-12 text-center">
                <h2>Skills</h2>
                <ul class="tags py-4">
                    <li class="s-tag">C#</li>
                    <li class="s-tag">.NET Core</li>
                    <li class="s-tag">Azure</li>
                    <li class="s-tag">Web Applications</li>
                    <li class="s-tag">Web API</li>
                    <li class="s-tag">SQL Databases</li>
                    <li class="s-tag">Scrum</li>
                    <li class="s-tag">Vue.js</li>
                    <li class="s-tag">Javascript</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import 'particles.js'

    export default {
        name: 'Home',
        mounted() {
            this.$nextTick(() => {
                this.initHeader();
            })
        },
        methods: {
            initHeader() {

                // load particles-js
                // eslint-disable-next-line no-undef
                particlesJS.load('particles-header', 'js/particlesheader-config.json', function () {
                    //console.log('callback - particles-js config loaded');
                });
            }
        }
    }
</script>

<style scoped>

    /*  about */
    #about-me {
        position: relative;
        min-height: 400px;
        margin-top: -250px;
        max-width: 825px;
        font-weight: 300;
        font-size: 1.3em;
        border-radius: 1em;
    }
    
        @media (max-width: 768px) {
             #about-me {
                font-size: 1em;
            }
        }

    #about-me-content {
        padding: 1em 0.5em;
    }

    #about-me-footer {
        height: 70px;
        background-color: #016b87; /*#2196f3;*/
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
    }

        #about-me-footer > a {
            color: #fff;
        }

    #about-me h1 {
        font-size: 3em;
        font-weight: 500;
        color: #016b87;
    }
    #about-me h2 {
        font-size: 1.5em;
    }
    #about-me p{
        font-size: 1.2em;
    }

        #about-me p a {
            color:#47a1ba;
        }

    #about-me .bi {
        font-size: 0.9em;
        vertical-align: top;
    }

    /* tags */
    ul.tags{
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .s-tag {
        display: inline-block;
        padding: 0.5em;
        margin: 0 0.8em 0.8em 0;
        border: 1px solid #808080;
        border-radius: 0.5em;
    }

    .icon-link {
        width: 3em;
        justify-content: center;
        font-size: 1.5em;
    }

        .icon-link i {
            transition: 0.4s;
        }

        .icon-link:hover i {
            color: #c8f0fb;
            font-size: 1.2em;
        }

    .my-skills{
        margin-top: 4em;
    }
</style>
